<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="showAddCategory = true">添加分类</el-button>
    <CategoryFilter :filterForm="categoryForm" :uploadFilter="updateList" />
    <el-table style="margin-top: 20px" :data="categoryList" class="thead-light" v-loading="showLoading" stripe
      @sort-change="sortChange">
      <el-table-column v-for="(item,index) in categoryTable" :key="index" :label="item.label"
        :prop="item.prop" :min-width="item.min_width" :sortable="item.sortable">
        <template slot-scope="scope">
          <PopoverInputEdit v-if="item.prop === 'sort'" v-model="scope.row.sort" input-type="number"
            :validator="validator" @confirm="upDateSort(scope.row)" />
          <span v-else>{{scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
      <el-table-column label="显示" width="100">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.is_show" :true-label="1" :false-label="0"
            @change="changeShow(scope.row.id,$event)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button style="color: #3576FF" size="small" type="text" @click="handleEditCategory(scope.row)">编辑
          </el-button>
          <el-button style="color: #3576FF" size="small" type="text" @click="handleTransfer(scope.row.id)">转移
          </el-button>
          <el-button style="color: #3576FF" size="small" type="text" @click="handleDelCategory(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AddCategory :showAddCategory="showAddCategory" :editObj="editObj" @closeAddDialog="closeAddDialog"
      @updateData="updateData"></AddCategory>
    <CategoryTransfer :showTransfer="showTransfer" :transferId="transferId"
      @closeTransfer="showTransfer = false" @transferSuccess="transferSuccess"></CategoryTransfer>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage"></Pagination>
  </div>
</template>

<script>
import CategoryFilter from "../components/CircleCategory/CategoryFilter";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import {
  categoryList,
  categoryUpdateShow,
  delCategory,
  UpdateSort,
} from "../api/category-list";
import Pagination from "@/base/components/Default/Pagination";
import AddCategory from "../components/CircleCategory/AddCategory";
import CategoryTransfer from "../components/CircleCategory/CategoryTransfer";
export default {
  name: "circle-category",
  data() {
    return {
      categoryForm: {
        page_size: 15,
        is_demand: -1,
      },
      pageData: {},
      categoryTable: [
        { label: "ID", prop: "id", min_width: 100 },
        { label: "排序", prop: "sort", min_width: 100 },
        { label: "分类名称", prop: "name", min_width: 100 },
        { label: "所属类型", prop: "demand_text", min_width: 100 },
        {
          label: "资源数量",
          prop: "content_count",
          min_width: 100,
          sortable: true,
        },
      ],
      validator: {
        type: "integer",
        min: 1,
        message: "最小不能低于1的整数",
        trigger: "blur",
      },
      categoryList: [],
      showLoading: false,
      showAddCategory: false,
      editObj: {},
      showTransfer: false,
      transferId: "",
    };
  },
  components: {
    Pagination,
    CategoryFilter,
    AddCategory,
    CategoryTransfer,
    PopoverInputEdit,
  },
  created() {
    this.getCategoryList(this.categoryForm);
  },
  methods: {
    handleDelCategory(data) {
      let delTitle = "";
      let delBtn = "";
      if (data.content_count > 0) {
        delTitle = "该分类下还有信息内容，建议先进行内容转移，再删除";
        delBtn = "仍要删除";
      } else {
        delTitle = "确认要将该分类删除吗？删除后无法恢复。";
        delBtn = "删除";
      }
      this.$confirm(delTitle, "提示", {
        confirmButtonText: delBtn,
        cancelButtonText: "取消",
        type: "error",
      })
        .then((res) => {
          this.showLoading = true;
          delCategory({ id: data.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.RefreshList();
            })
            .catch((err) => {
              this.showLoading = false;
            });
        })
        .catch((err) => {});
    },
    transferSuccess() {
      this.showTransfer = false;
      this.transferId = 0;
      this.RefreshList();
    },
    handleTransfer(id) {
      this.showTransfer = true;
      this.transferId = id;
    },
    closeAddDialog() {
      this.showAddCategory = false;
      this.editObj = {};
    },
    handleEditCategory(data) {
      this.editObj = JSON.parse(JSON.stringify(data));
      this.showAddCategory = true;
    },
    updateData() {
      this.showAddCategory = false;
      this.RefreshList();
    },
    updateList(data) {
      this.categoryForm = { ...this.categoryForm, ...data };
      this.getCategoryList(this.categoryForm);
    },
    changeShow(id, e) {
      let data = {
        id: id,
        is_show: e,
      };
      this.showLoading = true;
      categoryUpdateShow(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.RefreshList();
        })
        .catch((err) => {
          this.showLoading = false;
        });
    },
    upDateSort(row) {
      const data = {
        id: row.id,
        sort: row.sort,
      };
      this.showLoading = true;
      UpdateSort(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.RefreshList();
        })
        .catch((err) => {
          this.showLoading = false;
        });
    },
    sortChange(e) {
      this.categoryForm.order_by = e.prop;
      this.RefreshList();
    },
    changPage(data) {
      this.categoryForm.page_size = data.page_size;
      let requestData = { ...this.categoryForm, ...data };
      this.getCategoryList(requestData);
    },
    // 刷新列表
    RefreshList() {
      const data = {
        page: this.pageData.crrent_page,
        ...this.categoryForm,
      };
      this.getCategoryList(data);
    },
    getCategoryList(requestData) {
      this.showLoading = true;
      this.pageData = {};
      categoryList(requestData)
        .then((res) => {
          this.categoryList = res.data.data;
          this.pageData = res.data;
          this.showLoading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="60" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="45px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="类型：" prop="is_demand">
          <el-select v-model="is_demand" placeholder="请选择">
            <el-option v-for="item in selectList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      selectList: [
        { label: "全部", value: -1 },
        { label: "供应", value: 0 },
        { label: "需求", value: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    is_demand: {
      get() {
        return this.form.is_demand == -2 ? "" : this.form.is_demand;
      },
      set(val) {
        this.form.is_demand = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
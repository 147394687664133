<template>
  <el-dialog title="数据转移" :visible="showTransfer" width="504px" class="dialog-vertical" @close="closeAdd">
    <el-form :model="transferForm" size="medium" label-width="100px" :rules="form_rules" ref="transferForm">
      <el-form-item label="所属分类">
        <el-radio v-model="transferForm.is_demand" :label="1" @change="handleChangeRadio">需求</el-radio>
        <el-radio v-model="transferForm.is_demand" :label="0" @change="handleChangeRadio">供应</el-radio>
      </el-form-item>
      <el-form-item label="选择分类" prop="merge_category_id">
        <el-select v-model="transferForm.merge_category_id" placeholder="请选择">
          <el-option v-for="item in optionList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button size="small" type="primary" :loading="saveLoading"
        @click="handleTransferCategory('transferForm')">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { allCategory, mergeCategory } from "../../api/category-list";
export default {
  name: "CategoryTransfer",
  props: ["showTransfer", "transferId"],
  data() {
    return {
      transferForm: {
        is_demand: 1,
        del_category_id: "",
        merge_category_id: "",
      },
      optionList: [],
      form_rules: {
        merge_category_id: [
          { required: true, message: "请选择分类", trigger: "change" },
          { required: true, validator: this.transferPass, trigger: "change" },
        ],
      },
      saveLoading: false,
    };
  },
  watch: {
    transferId() {
      this.transferForm.del_category_id = this.transferId;
    },
  },
  created() {
    this.getAllCategory();
  },
  methods: {
    handleChangeRadio() {
      this.getAllCategory();
    },
    getAllCategory() {
      allCategory({ is_demand: this.transferForm.is_demand })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {});
    },
    //验证所选分类是否重复
    transferPass(rule, value, callback) {
      if (value === this.transferId) {
        callback(new Error("与当前分类重复"));
      } else {
        callback();
      }
    },
    handleTransferCategory(configForm) {
      this.$refs[configForm].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          mergeCategory(this.transferForm)
            .then((res) => {
              this.$emit("transferSuccess");
              this.transferForm = {
                is_demand: 1,
                del_category_id: "",
                merge_category_id: "",
              };
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeAdd() {
      this.$emit("closeTransfer");
    },
  },
};
</script>

<style scoped>
</style>
